import React from 'react';
import { useTranslation } from 'react-i18next';
import { Footer as DestinyFooter } from '@myriadgenetics/web-components/dist/react';
import { Text3, H3 } from '../../elements/typography';
import FooterNav from './components/footerNav';
import SocialMenu from './components/socialMenu';
import alternativeLogo from '../../assets/myriad-logo.png';
import { publicUrl } from '../../helpers/url';
import { footerNav, footerMenuNav, socialMenu } from './constants';
import './index.scss';
import { FooterProps } from './types';

function Footer({ isAlternativeStyle, isChatbot, isDemo }: FooterProps) {
  const { t } = useTranslation();
  const footerClassName = React.useMemo(() => {
    if (isAlternativeStyle) {
      return 'alternative-style';
    }

    if (isChatbot) {
      return 'myra-footer';
    }
    return 'classic';
  }, [isAlternativeStyle, isChatbot]);

  return (
    <footer className={`footer ${footerClassName}`}>
      {!isAlternativeStyle ? (
        <DestinyFooter className={footerClassName || 'footer'} variant="patient" copyright=" " />
      ) : (
        <>
          <div className="footer__menu-wrapper">
            <div className="footer__menu">
              <FooterNav items={footerMenuNav} />
              <SocialMenu items={socialMenu} />
            </div>
          </div>
          <div className="footer__top">
            <a href={publicUrl} className="footer__logo-link" title={t('logo.alt')}>
              <img alt={t('logo.alt')} src={alternativeLogo} className="footer__logo" />
            </a>
            <H3>{t('footer.text')}</H3>
          </div>
          <div className="footer__bottom">
            <FooterNav items={footerNav} isSimpleView />
            <Text3 className="footer__copyright">{t('footer.copyright')}</Text3>
          </div>
        </>
      )}
      {isDemo && !isAlternativeStyle && <div className="survey-container__demo-indicator">{t('demoIndicator')}</div>}
    </footer>
  );
}

export default Footer;
