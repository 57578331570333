import React, { useContext, useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '@myriadgenetics/tokens/dist/tokens.css';
import 'url-search-params-polyfill';
import {
  ContinueType,
  HttpMethod,
  Node,
  NodeTypes,
  OptionKeyValue,
  PageNode,
  RecoverTypes,
  SurveyAccount,
  SurveyAccountConfigurations,
} from '@myriadgenetics/mgh-types';
import { SurveyContainerStep, SurveyContainerLocationState, LocationState, PatientIdentifierResponse } from './types';
import {
  attachOnBeforeUnloadEvent,
  updateResponses,
  findNodeByIdFromSurvey,
  getIsLangPath,
  gotoNextNode,
  getTagForNode,
  getSurveyById,
  initializeSurvey,
  isForceStartOver,
  startState,
} from './utils';
import { getUrlSlug, getPatientIdentifiers } from '../../helpers/url';
import { startOver, useHistory, useLocation } from '../../helpers/routing';
import { SessionContext } from '../app';
import LangStep from './steps/lang';
import StartPage from '../start-page';
import TextLogo from '../../elements/text-logo';
import PatientDemographicsDisplay from './components/patientDemographicsDisplay';
import ChatbotWorkflow from '../survey-node/chatbot-workflow';
import Logger from '../../helpers/logger';
import Nodes from '../survey-node';
import { isDemoUrl, isGalileoUrl, isT404PUrl, isChatbotUrl, isSimonMedUrl } from '../../helpers/url';
import ProviderSelect from '../provider-select';
import Footer from '../footer';
import Header from '../header';
import Loading from '../loading';
import Config from '../../helpers/config';
import SessionTimer from './session-timer';
import CountdownPage from '../countdown-page';
import auxiliaryPage from '../../helpers/auxiliary-page';
import PageNodeReact from '../survey-node/page-node';
import ProviderSelectModal from '../provider-select/modal';
import { pushGTMData, GTMDataProps } from '../../helpers/googleTagManager';
import {
  SURVEY_NODE_CLASSNAME,
  SURVEY_RESULTS_CLASSNAME,
  DELAY_SECONDS,
  CHATBOT_DELAY_SECONDS,
  TIMEOUT_SECONDS,
  CHATBOT_TIMEOUT_SECONDS,
  FOOTER_STYLE,
  CONTENT_ALLOCATION,
  CLINIC_LOGO_CONFIGURATION_KEY,
} from './constants';
import Modal from '../../elements/modal';
import { setSurveyAccountCustomStyles } from '../../helpers/surveyAccount';
import './index.scss';
import '../../themes/themes.scss';
import './index.scss';
import { findNodeById, hasResultsNodeName } from '../survey-node/helpers';
import { SurveyAccountsContext } from '../../context/SurveyAccountsContext';
import { Helmet } from 'react-helmet';
import PreloadExternalResources from '../preload-external-resources';
import { get as _get, find as _find } from 'lodash';

import '@myriadgenetics/tokens/dist/tokens.css';

function SurveyContainer(props: any) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const session = useContext(SessionContext);
  const history = useHistory();
  const location = useLocation() as LocationState;
  const {
    locSession,
    initData,
    surveyStep,
    locSurveyId,
    survey,
    nodeId,
    locResponses,
    mghUrl,
    clinicName,
    surveyAccount,
    nodeWorkFlowState = {},
  }: SurveyContainerLocationState = location.state || {};

  // basic setups to run once
  const urlSlug = getUrlSlug(mghUrl, location).toLowerCase();
  const isDemo = isDemoUrl(urlSlug);
  const aGalileoUrl = isGalileoUrl(urlSlug);
  const aT404PUrl = isT404PUrl(urlSlug);
  const aChatbotUrl = isChatbotUrl(urlSlug);
  const pathname = location.pathname;

  const responses = locResponses || {};

  // setup some state hooks
  const [surveyAccounts, setSurveyAccounts] = useState<SurveyAccount[] | null | undefined>(null);
  const [isShowSurveyModal, setIsShowSurveyModal] = useState<boolean>(false);
  const [surveyId, setSurveyId] = useState<number | null>(locSurveyId || null);
  const [timedOutOnUrl, setTimedOutOnUrl] = useState<string | null | undefined>(null);
  const [atResults, setAtResults] = useState<boolean>(surveyStep === SurveyContainerStep.Results);
  const [surveyAccountId, setSurveyAccountId] = useState<number | null>(null);
  const [isShowInChatbot, setIsShowInChatbot] = useState<boolean>(false);
  const [clinicLogo, setClinicLogo] = useState<string>('');

  const [account, setAccount] = useState<SurveyAccount | null>(null);
  // must proceed hooks
  const timedOut = !!timedOutOnUrl;

  // state management to store responses
  const onResponseUpdated = (answerKey: string, answer: any, gtmData?: Partial<GTMDataProps>) => {
    const theResponses = updateResponses({
      answerKey,
      answer,
      responses,
      gtmData,
      history,
      location,
      aT404PUrl,
    });

    if (Config.isDev()) {
      console.log(`Updated responses for ${answerKey}`, answer);
      console.log('The full answers post processing', theResponses);
    }
  };

  const performPatientIdentifiersCheck = () => {
    const patientIdentifiers = getPatientIdentifiers();

    patientIdentifiers.forEach(({ key, responsePath, type }) => {
      if (!key) return;
      const patientIdentifierValue = _get(location.state?.queryParams, key);

      if (!patientIdentifierValue) return;

      const patientIdentifiersResponse: PatientIdentifierResponse[] = _get(responses, responsePath, []);
      const newPatientIdentifierResponse: PatientIdentifierResponse = {
        use: type,
        value: patientIdentifierValue,
      };

      const isNewPatientIdentifierInResponse = !!_find(patientIdentifiersResponse, newPatientIdentifierResponse);

      if (!isNewPatientIdentifierInResponse) {
        onResponseUpdated(responsePath, [
          ...patientIdentifiersResponse,
          {
            use: type,
            value: patientIdentifierValue,
          },
        ]);
      }
    });
  };

  useEffect(() => {
    performPatientIdentifiersCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.queryParams]);

  // some booleans
  const isLangPath = getIsLangPath(pathname, urlSlug);
  const goForwardOnContinue = history.goForwardOnContinue();
  const shouldShowLoading = !aChatbotUrl && !survey;
  let withBg = false;
  let withLogo = false;
  let internal = null;

  const saveClinicLogoFromSurveyConfig = (surveyAccount: SurveyAccount) => {
    const retrievedClinicLogo = surveyAccount.configurations.find(
      (config: SurveyAccountConfigurations) => config.configurationKey === CLINIC_LOGO_CONFIGURATION_KEY,
    );
    if (retrievedClinicLogo?.configurationValue) setClinicLogo(retrievedClinicLogo.configurationValue);
  };

  // TODO: move to utils
  const setProvider = (surveyAccount: SurveyAccount, clinicName: string, doReplace = false) => {
    if (surveyId !== surveyAccount.surveyId) {
      setSurveyId(surveyAccount.surveyId);
    }

    const method = doReplace ? history.replace : history.push;
    const additionalState = doReplace ? startState(history) : {};
    saveClinicLogoFromSurveyConfig(surveyAccount);

    method(`/${urlSlug}/provider`, {
      ...location.state,
      surveyAccount,
      surveyStep: SurveyContainerStep.Fetch,
      survey: undefined,
      nodeId: undefined,
      locResponses: { ...initData, ...responses },
      clinicName,
      ...additionalState,
    });
  };

  useEffect(() => {
    setSurveyAccountCustomStyles(surveyAccount);
  }, [surveyAccount]);

  useEffect(() => {
    document.title = location.state?.title || t('myGeneHistory');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.title]);

  useEffect(() => {
    setIsShowSurveyModal(survey?.configuration?.isShowInModal);
  }, [survey, setIsShowSurveyModal]);

  useEffect(() => {
    setIsShowInChatbot(aChatbotUrl);
  }, [survey, setIsShowInChatbot, aChatbotUrl, location]);

  // hook that runs when history changes, or survey changes, etc.
  useEffect(() => {
    const noStepOrBackButtonClickedAndLangStep = !surveyStep || (history.action === 'POP' && surveyStep === SurveyContainerStep.Lang);
    const isFetchStepAndHasSurveyId = surveyStep === SurveyContainerStep.Fetch && surveyId !== null;
    const isAtResultsButNotAtSetToAtResults = surveyStep === SurveyContainerStep.Results && !atResults;
    if (noStepOrBackButtonClickedAndLangStep) {
      initializeSurvey({
        urlSlug,
        history,
        location,
        session,
        title: t('pageTitles.lang'),
      });
    } else if (isFetchStepAndHasSurveyId) {
      getSurveyById(surveyId)
        .then((selSurvey) => {
          const node = selSurvey.startNode;
          setTimeout(() => {
            history.replace(`/${urlSlug}/survey/node/${node.id}`, {
              ...location.state,
              title: t(node.name || `myGeneHistory - ${node.id}`),
              surveyStep: SurveyContainerStep.Survey,
              survey: selSurvey,
              nodeId: node.id,
            });
          }, 50);
        })
        .catch((e) => {
          Logger.logError(e);
          auxiliaryPage.routeToAuxPage(history, {
            titleKey: 'errorPages.network.title',
            messageKey: 'errorPages.network.message',
            recoverTextKey: 'startOver',
            recoverAction: RecoverTypes.Restart,
          });
        });
    } else if (isAtResultsButNotAtSetToAtResults) {
      setAtResults(true);
    }
  }, [session, surveyStep, history, atResults, t, surveyId, location, location.state, urlSlug]);

  useEffect(() => {
    const { curNodeId = survey?.startNode } = nodeWorkFlowState;
    const curNode = findNodeById(curNodeId, survey?.nodes);
    const isNotCurrentNodeResults = curNode && curNode.name && !hasResultsNodeName(curNode.name);
    const notAtLangPageAndNotAtResultsPage = !isLangPath && surveyStep !== SurveyContainerStep.Results && isNotCurrentNodeResults;

    attachOnBeforeUnloadEvent(notAtLangPageAndNotAtResultsPage ? true : false);
    return () => {
      attachOnBeforeUnloadEvent(false);
    };
  }, [survey, nodeWorkFlowState, isLangPath, surveyStep]);

  useEffect(() => {
    const missingSurveyAccountIdFromResponses = surveyAccountId && !responses.surveyAccountId;
    if (missingSurveyAccountIdFromResponses) {
      onResponseUpdated('surveyAccountId', surveyAccountId, { label: surveyAccount?.providerName });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responses.surveyAccountId, surveyAccountId]);

  if (isForceStartOver(atResults, surveyStep)) {
    startOver();
    return null;
  }

  const notLangPageAndSessionMisMatch = !isLangPath && session !== locSession;
  if (notLangPageAndSessionMisMatch) {
    history.replace(`/${urlSlug}`, {});
    return null;
  }

  if (timedOut) {
    // clear out timed out on url change
    if (timedOutOnUrl !== location.pathname) {
      setTimedOutOnUrl(null);
    }
    withBg = true;
    internal = (
      <CountdownPage
        delay={aChatbotUrl ? CHATBOT_DELAY_SECONDS : DELAY_SECONDS}
        onContinue={() => setTimedOutOnUrl(null)}
        onStartOver={startOver}
        onTimeout={startOver}
        theme={survey?.configuration?.theme}
      />
    );
  } else {
    switch (surveyStep) {
      case SurveyContainerStep.Lang:
        withBg = !aChatbotUrl;
        internal = (
          <LangStep
            urlSlug={urlSlug}
            setSurveyAccounts={setSurveyAccounts}
            setProvider={setProvider}
            shouldHide={aChatbotUrl}
            onContinue={() => {
              history.push(`/${urlSlug}/start`, {
                ...location.state,
                title: t('pageTitles.start'),
                surveyStep: SurveyContainerStep.Start,
                ...startState(history),
              });
            }}
          />
        );
        break;
      case SurveyContainerStep.Start:
        withBg = !aChatbotUrl;
        internal = (
          <StartPage
            onContinue={() => {
              history.push(`/${urlSlug}/provider`, {
                ...location.state,
                title: t('pageTitles.provider'),
                surveyStep: SurveyContainerStep.Provider,
              });
            }}
          />
        );
        break;
      case SurveyContainerStep.Provider:
        withBg = !aChatbotUrl;
        if (!surveyAccounts) {
          Logger.logError('Reached provider select without survey account information.');
          auxiliaryPage.routeToAuxPage(history, {
            titleKey: 'errorPages.network.title',
            messageKey: 'errorPages.network.message',
            recoverTextKey: 'startOver',
            recoverAction: RecoverTypes.GoBack,
          });
        } else {
          internal = (
            <div className="survey-container__provider">
              <ProviderSelect onProviderSelected={setProvider} surveyAccounts={surveyAccounts} />
            </div>
          );
        }
        break;
      case SurveyContainerStep.Fetch:
        withBg = !aChatbotUrl;
        internal = shouldShowLoading ? (
          <div className="survey-container__fetch">
            <Loading translatedMsg={t('loading.assessment')} />
          </div>
        ) : null;
        break;
      case SurveyContainerStep.Survey:
        // TODO: refactor...this code is so ugly
        if (survey) {
          const node = findNodeByIdFromSurvey(nodeId, survey);
          if (node) {
            withBg = !aChatbotUrl;
            withLogo = true;
            const Tag = getTagForNode(node, Nodes);
            const isT40SurveyAndShouldShow = isShowSurveyModal && aT404PUrl && surveyAccounts && !surveyAccountId;
            if (isT40SurveyAndShouldShow) {
              internal = (
                <ProviderSelectModal surveyAccounts={surveyAccounts} urlSlug={urlSlug} onProviderSelected={(providerId) => setSurveyAccountId(providerId)} />
              );
            } else {
              internal = Tag && (
                <React.Fragment>
                  <Tag
                    node={node}
                    gotoNextNode={(id: number, replace: boolean) => {
                      const params = {
                        id,
                        goForwardOnContinue,
                        replace,
                        curNode: node,
                        survey,
                        history,
                        urlSlug,
                        location,
                        t,
                      };
                      gotoNextNode(params);
                    }}
                    theme={survey?.configuration?.theme}
                    gtmDataTracking={aT404PUrl ? pushGTMData : undefined}
                    responses={responses}
                    onResponseUpdated={onResponseUpdated}
                  />
                </React.Fragment>
              );
            }
          } else {
            // TODO error out
          }
        }
        break;
      case SurveyContainerStep.Final:
        withLogo = true;
        const lastQuestion: PageNode = {
          id: -10,
          type: NodeTypes.Page,
          name: 'Last Question',
          flows: [],
          components: [
            {
              id: -1,
              type: 'YES_NO',
              data: {
                questionKey: 'inOfficeQuestion',
                answerKey: 'inOffice',
                required: true,
              },
            },
          ],
          data: {
            continueType: ContinueType.AutoFixed,
            subtitle: 'subtitles.inOffice',
          },
        };
        const process = () => {
          history.push(`/${urlSlug}/process`, {
            ...location.state,
            title: t('pageTitles.processing'),
            surveyStep: SurveyContainerStep.Process,
            timing: {
              ...location.state.timing,
              end: new Date().toISOString(),
            },
          });
        };
        if (aGalileoUrl || aT404PUrl) {
          process();
          internal = <Loading />;
        } else {
          internal = (
            <div className="survey-container__final">
              <PageNodeReact responses={responses} onResponseUpdated={onResponseUpdated} node={lastQuestion} gotoNextNode={process} />
            </div>
          );
        }
        break;
      case SurveyContainerStep.Process:
        internal = (
          <div className="survey-container__process">
            {history.action === 'PUSH' && (
              <Nodes.EndpointCallNode
                gotoNextNode={(id) => {
                  history.replace(`/${urlSlug}/results`, {
                    ...location.state,
                    title: t('pageTitles.results'),
                    surveyStep: SurveyContainerStep.Results,
                    resultsState: { nodeId: id },
                  });
                }}
                node={{
                  id: -9,
                  type: NodeTypes.EndpointCall,
                  name: 'Save Node',
                  description: 'Built in node for saving results',
                  flows: survey.endNode.flows,
                  data: {
                    titleKey: 'criteriaCheck.title',
                    messageKey: 'criteriaCheck.message',
                    errorKey: 'criteriaCheck.error',
                    continueType: ContinueType.AutoFixed,
                    answerKey: 'surveyEntrySave',
                    endpointHttpMethod: HttpMethod.Post,
                    endpointPath: '/patient-survey-entries',
                  },
                }}
                onResponseUpdated={onResponseUpdated}
                responses={responses}
              />
            )}
          </div>
        );
        break;
      case SurveyContainerStep.Results:
        withBg = true;
        withLogo = true;
        const resultNode = survey.nodes.find((n: Node) => n.id === location.state.resultsState.nodeId);

        // TODO
        //  Temporary decision for button view.
        //  When we add the BUTTON component to all results nodes,
        //  which using Close button, remove this code and on 603 line.
        const findClosePageButton = resultNode?.components.find((component: any) => {
          return component.type === 'BUTTON' && component.data.buttonType === 'close-page';
        });

        if (!resultNode) {
          Logger.logError(`Could not find resultNode for ${surveyId}.`);
          auxiliaryPage.routeToAuxPage(history, {
            titleKey: 'errorPages.network.title',
            messageKey: 'errorPages.network.message',
            recoverTextKey: 'startOver',
            recoverAction: RecoverTypes.Restart,
          });
        } else {
          const Tag = getTagForNode(resultNode, Nodes);
          internal = (
            <div className={SURVEY_RESULTS_CLASSNAME}>
              {resultNode && (
                <Tag
                  node={resultNode}
                  gotoNextNode={startOver}
                  responses={responses}
                  onResponseUpdated={onResponseUpdated}
                  isResultsPage={true} // TODO leverage this in pagenode
                  isClosePageButtonExist={!!findClosePageButton}
                  isDemo={isDemo}
                />
              )}
            </div>
          );
        }
        break;
    }
  }

  const withNav = surveyStep !== SurveyContainerStep.Lang && !aT404PUrl && !aChatbotUrl;
  const withBack = surveyStep !== SurveyContainerStep.Lang && surveyStep !== SurveyContainerStep.Results && !timedOut;
  const withPatientDemographics = surveyStep === SurveyContainerStep.Results && (!aGalileoUrl || !aT404PUrl || !aChatbotUrl);
  const withFooter = !isSimonMedUrl();

  const setSurveyAccountHandler = (provider: OptionKeyValue[]) => {
    if (!Array.isArray(provider) || provider.length < 1) return;
    const answer = surveyAccounts?.filter((account) => account.providerName === provider[0].optionValue)[0];
    if (answer) setAccount(answer);
  };

  const getConfigValueFromSurveyAccount = (defaultValue = '', key: string, surveyAccountConfig?: SurveyAccountConfigurations[]) => {
    const faviconIndex = surveyAccountConfig ? surveyAccountConfig.findIndex(({ configurationKey }) => configurationKey === key) : -1;
    return faviconIndex !== -1 && surveyAccountConfig ? surveyAccountConfig[faviconIndex].configurationValue : defaultValue;
  };

  return (
    <SurveyAccountsContext.Provider value={{ surveyAccounts, surveyAccount: account, setSurveyAccount: setSurveyAccountHandler }}>
      <PreloadExternalResources resources={survey?.configuration?.preloadExternalResources} />
      <Helmet htmlAttributes={{ lang: language }}>
        <title>{getConfigValueFromSurveyAccount('myGeneHistory', 'pageTitle', surveyAccount?.configurations)}</title>
        <link rel="icon" id="favicon-link" href={getConfigValueFromSurveyAccount('/favicon.ico', 'favicon', surveyAccount?.configurations)} sizes="32x32" />
      </Helmet>
      <div
        className={`survey-container lang-${language}
        ${withBg && !survey?.contentBottomNode ? 'survey-container--classic' : ''}
        ${survey?.configuration?.theme ? 'survey-container--' + survey?.configuration?.theme : ''}`}
      >
        <Prompt
          when={surveyStep === SurveyContainerStep.Results}
          message={(location, action): string | boolean => action === 'PUSH' || (t('forceRestartWarning') as string)}
        />
        {!timedOut && surveyStep !== SurveyContainerStep.Lang && (
          <SessionTimer
            key={location.key}
            lastInteractionTime={new Date().getTime()}
            timeoutSeconds={aChatbotUrl ? CHATBOT_TIMEOUT_SECONDS : TIMEOUT_SECONDS}
            timedOut={(secondsLeft) => {
              if (secondsLeft > -DELAY_SECONDS) {
                setTimedOutOnUrl(location.pathname);
              } else {
                startOver();
              }
            }}
          />
        )}
        <div
          className={`survey-container__main
          ${survey?.configuration?.isShowInModal ? 'survey-container__main--modal-view' : ''}
          ${!withNav ? 'survey-container__main--hide-nav' : ''}`}
        >
          {surveyStep !== SurveyContainerStep.Fetch && (
            <Header
              isChatbot={aChatbotUrl}
              isSimonMedUrl={isSimonMedUrl()}
              showLangSelect={surveyStep !== SurveyContainerStep.Lang}
              clinicName={clinicName}
              clinicLogo={clinicLogo}
              surveyAccount={location?.state?.surveyAccount}
              withNav={withNav}
              withBack={withBack}
            />
          )}

          {survey?.contentTopNode && (
            <Nodes.ContentNodeReact
              type={CONTENT_ALLOCATION.Top}
              node={survey.contentTopNode}
              responses={responses}
              surveyAccount={location.state.surveyAccount}
              onResponseUpdated={onResponseUpdated}
              onContinue={() => setIsShowSurveyModal(true)}
            />
          )}
          <div className={SURVEY_NODE_CLASSNAME}>
            {withLogo && (!aT404PUrl || !aChatbotUrl) && (
              <div className="survey-container__logo">
                <TextLogo name={clinicName} />
                {withPatientDemographics && <PatientDemographicsDisplay responses={responses} />}
              </div>
            )}
            {survey?.configuration?.isShowInModal ? (
              <Modal
                triggerDisplay={'setDisplay'}
                triggerText={'modal'}
                theme={survey?.configuration?.theme}
                isShow={isShowSurveyModal}
                setIsShow={setIsShowSurveyModal}
              >
                {internal}
              </Modal>
            ) : isShowInChatbot && survey !== undefined && survey.nodes && survey.startNode && !timedOut ? (
              <ChatbotWorkflow
                className="chatbot-workflow"
                nodes={survey.nodes}
                initNode={survey.startNode}
                gotoNextNode={(id: number | null | undefined, replace: boolean) => {
                  const node = findNodeByIdFromSurvey(nodeId, survey);
                  const params = {
                    id,
                    goForwardOnContinue,
                    replace,
                    curNode: node,
                    survey,
                    history,
                    urlSlug,
                    location,
                    t,
                  };
                  gotoNextNode(params);
                }}
                onResponseUpdated={onResponseUpdated}
                responses={responses}
                surveyAccount={location.state.surveyAccount}
                theme={survey?.configuration?.theme && 'chatbot'}
              />
            ) : (
              // final ternary...just render the internal tag.
              internal
            )}
          </div>
          {Config.isDev() && (
            <div
              data-test-id="startOverBtn"
              style={{
                color: 'rgba(255, 255, 255, 0)',
                fontSize: '2px',
                position: 'absolute',
                bottom: '1rem',
                left: '0',
              }}
              onClick={startOver}
            >
              *
            </div>
          )}
          {survey?.contentBottomNode && (
            <Nodes.ContentNodeReact
              type={CONTENT_ALLOCATION.Bottom}
              node={survey.contentBottomNode}
              responses={responses}
              surveyAccount={location.state.surveyAccount}
              onResponseUpdated={onResponseUpdated}
              onContinue={() => setIsShowSurveyModal(true)}
            />
          )}
        </div>
        {withFooter && !aChatbotUrl && (
          <Footer isDemo={isDemo} isChatbot={aChatbotUrl} isAlternativeStyle={survey?.configuration?.footer === FOOTER_STYLE.Alternative} />
        )}
      </div>
    </SurveyAccountsContext.Provider>
  );
}

export default SurveyContainer;
