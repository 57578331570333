import React, { useState } from 'react';
// TODO: (Simon) update to pull form clinicLogo survey account configuration
import { useTranslation } from 'react-i18next';
import { Header as DestinyHeader } from '@myriadgenetics/web-components/dist/react';
import { useHistory } from '../../helpers/routing';
import { publicUrl } from '../../helpers/url';
import Link from '../../elements/link';
import Logger from '../../helpers/logger';
import './index.scss';
import { HeaderProps } from './types';
import LanguageSelect, { SelectionType } from '../language-select';
import mghLogo from '../../assets/mygenehistory.svg';

function Header({ isChatbot, isSimonMedUrl, surveyAccount, clinicName, showLangSelect, withNav, withBack, clinicLogo }: HeaderProps) {
  const { t } = useTranslation();
  const [isLogoLoaded, setIsLogoLoaded] = useState(false);
  const imgTitleAndAlt = isChatbot ? surveyAccount?.clinicName || t('chatbot.logo.alt') : t('logo.alt');
  const history = useHistory();

  return (
    <div className="survey-container__header" data-testid="survey-container__header">
      <DestinyHeader>
        <div slot="leading">
          {clinicLogo ? (
            <div>
              <a href={publicUrl} className={`${isChatbot ? 'chatbot__' : ''}header__logo_link`} title={imgTitleAndAlt}>
                <img
                  alt={imgTitleAndAlt}
                  src={clinicLogo}
                  className="header__clinic-logo"
                  onLoad={() => setIsLogoLoaded(true)}
                  style={{ display: isLogoLoaded ? 'initial' : 'none' }}
                />
              </a>
            </div>
          ) : (
            <div className="header__clinic-name">{clinicName}</div>
          )}
        </div>
        {!isSimonMedUrl && (
          <div slot="trailing">
            <img className="mgh-logo" src={mghLogo} alt="MyGeneHistory Trademark" />
          </div>
        )}
      </DestinyHeader>
      <div className="survey-container__header-nav-wrapper">
        <div className="survey-container__header-nav">
          {withNav && withBack && (
            <Link
              className="survey-container__back"
              onClick={() => {
                Logger.logEvent('Clicked Back Link', null);
                history.goBack();
              }}
              size="small"
            >
              &lt; {t('back')}
            </Link>
          )}
          {showLangSelect && <LanguageSelect type={SelectionType.Dropdown} />}
        </div>
      </div>
    </div>
  );
}

export default Header;
